<template>
  <div id="fosterId">
    <div class="page-container-table">
      <!--phần header-->
      <button-all-header
        :contentBtnAdd="'Thêm quản lý bồi dưỡng'"
        :arrayExcel="arrayExcel"
        :showBtnMultiDelete="showBtnMultiDelete"
        @clickDelete="deleteItems"
        @clickDowloadSample="downloadExampleFile"
        @clickExportExcel="downloadExportFile"
        @importFile="importFileExcel($event)"
        @clickAdd="showModalCreate"
        @search="search($event)"
      />
      <!--Phần Bảng -->
      <vue-good-table
        ref="user-table"
        :columns="columns"
        :rows="dataTable || []"
        :sort-options="{
          enabled: false,
        }"
        :select-options="{
          enabled: true,
          vertialAlignTop: true,
          selectOnCheckboxOnly: true,
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true,
          selectAllByGroup: true,
        }"
        @on-selected-rows-change="selectRowTable"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <span
            v-if="props.column.field === 'code'"
            class="text-nowrap"
          >
            <b-badge
              pill
              class="light-code"
            >
              <span class="text-name">{{ props.row.code }}</span>
            </b-badge>
          </span>

          <!-- Column:Ngày áp dụng -->
          <span v-else-if="props.column.field === 'startDate'">
            <span class="text-nowrap">{{ props.row.startDate | formatDateToDDMM }}</span>
          </span>

          <!-- Chức năng -->
          <span v-else-if="props.column.field == 'action'">
            <span>
              <feather-icon
                v-b-tooltip.hover.top="'Chỉnh sửa'"
                icon="Edit3Icon"
                size="18"
                class="text-body"
                @click="showModalEdit(props.row.id)"
              />
              <feather-icon
                v-b-tooltip.hover.top="'Xóa'"
                icon="Trash2Icon"
                size="18"
                class="text-body ml-2"
                @click="deleteItem(props.row.id)"
              />
            </span>

          </span>
        </template>
      </vue-good-table>
      <my-pagination
        :totalItems="totalRecord"
        :currentPage="urlQuery.pageNumber"
        @pageClick="handlePageClick"
      />

      <!-- modal xóa-->
      <confirm-modal
        :id="confirmModalId"
        :content="modalContent"
        @accept="deleteAction"
      />

      <edit-foster
        :id="modalEditFoster"
        :type="modalType"
        :title="modalType==='add' ? 'Thêm quản lý bồi dưỡng': 'Chỉnh sửa quản lý bồi dưỡng'"
        :dataDetailFoster="dataDetailFoster"
        @handleCreateFoster="handleCreateFoster"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BRow, BCol, BFormGroup, BFormInput, BBadge, BDropdown,
  BDropdownItem, VBTooltip,
} from 'bootstrap-vue'
import { formatDateToDDMM } from '@core/utils/filter'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import VueGoodTable from '@/components/table/VueGoodTable.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import EditFoster from './components/EditFoster.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../constants/ConstantsApi'

export default {
  components: {
    ButtonAllHeader,
    VueGoodTable,
    MyPagination,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    ConfirmModal,
    BBadge,
    BDropdown,
    BDropdownItem,
    EditFoster,
    VBTooltip,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  filters: {
    formatDateToDDMM,
  },
  data() {
    return {
      modalType: '',
      modalEditFoster: 'edit-foster',
      totalRecord: 10,
      dataTable: [],
      columns: [
        {
          label: 'MÃ BỒI DƯỠNG',
          field: 'code',
          sortable: false,
        },
        {
          label: 'TÊN NGƯỜI LAO ĐỘNG',
          field: 'name',
          sortable: false,
        },
        {
          label: 'TÊN HIỆN VẬT',
          field: 'content',
          sortable: false,
        },
        {
          label: 'CHI PHÍ',
          field: 'price',
          sortable: false,
        },
        {
          label: 'NGÀY ÁP DỤNG',
          field: 'startDate',
          sortable: false,
        },
        {
          label: 'CHỨC NĂNG',
          field: 'action',
          width: '175px',
          sortable: false,
        },
      ],
      currentPage: 0,
      urlQuery: {
        key: '',
        pageSize: 10,
        pageNumber: 1,
      },
      confirmModalId: 'confirm-modal',
      modalContent: '',
      modalCreateInspector: 'comfirm-create',
      deleteIds: [],
      showBtnMultiDelete: false,
      dataDetailFoster: {},
      arrayExcel: [
        'code',
        'userId',
        'name',
        'money',
        'dateTime',
      ],
    }
  },
  created() {
    this.fetchDataFoster(this.urlQuery)
  },
  methods: {

    ...mapActions('foster', [
      'downloadExportFile',
      'downloadExampleFile',
      'getApiExcel',
    ]),

    // Lấy danh sách phân loại sức khỏe
    async fetchDataFoster(urlQuery) {
      this.$showLoading()
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_COMPENSATION_IN_KIND, {
        params: urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.totalRecord = data?.data?.totalRecord
      this.$hideLoading()
    },

    async handleCreateFoster(val) {
      if (this.modalType === 'edit') {
        const payload = {
          ...val,
          price: parseInt(val.price, 10),
          id: this.fosrterId,
        }
        this.$showAllPageLoading()
        await axiosApiInstance.put(ConstantsApi.EDIT_COMPENSATION_IN_KIND, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$bvToast.toast('Sửa thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditFoster)
          }
          this.fetchDataFoster(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
      if (this.modalType === 'add') {
        const payload = {
          ...val,
          price: parseInt(val.price, 10),
        }
        this.$showAllPageLoading()
        await axiosApiInstance.post(ConstantsApi.CREATE_COMPENSATION_IN_KIND, payload).then(response => {
          if (response.status === 200) {
            this.$hideAllPageLoading()
            this.$bvToast.toast('Thêm mới thành công', {
              title: 'Thông báo',
              variant: 'success',
              toaster: this.$toastPosition,
              solid: true,
            })
            this.$bvModal.hide(this.modalEditFoster)
          }
          this.fetchDataFoster(this.urlQuery)
        }).catch(e => {
          this.$hideAllPageLoading()
          this.$bvToast.toast(e.response.data?.errors[0], {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        })
      }
    },

    // Xử lý khi ấn nút phân trang
    handlePageClick(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.fetchDataFoster(this.urlQuery)
    },

    // lấy danh sách khi tìm kiếm
    search(val) {
      if (val != null) {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = val
        this.fetchDataFoster(this.urlQuery)
      } else {
        this.urlQuery.pageNumber = 1
        this.urlQuery.key = ''
        this.fetchDataFoster(this.urlQuery)
      }
    },

    // Mở modal tạo mới phân loại sức khỏe
    showModalCreate() {
      this.modalType = 'add'
      this.$bvModal.show(this.modalEditFoster)
    },

    // Mở modal chỉnh sửa phân loại sức khỏe
    async showModalEdit(id) {
      this.modalType = 'edit'
      this.fosrterId = id
      this.$bvModal.show(this.modalEditFoster)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_COMPENSATION_IN_KIND}${this.fosrterId}`)
      this.dataDetailFoster = data
    },

    // Chọn người dùng để thực hiện action
    selectRowTable(val) {
      const { selectedRows } = val
      this.showBtnMultiDelete = selectedRows.length > 0
    },

    // click xóa nhiều người dùng
    deleteItems() {
      this.deleteIds = this.$refs['user-table'].selectedRows.map(({ id }) => id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // xóa 1 người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xóa người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      await axiosApiInstance.post(ConstantsApi.DELETE_COMPENSATION_IN_KIND, this.deleteIds).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.fetchDataFoster(this.urlQuery)
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // import file exel
    async importFileExcel(event) {
      if (event) {
        this.getValidData(event)
      }
    },
    async getValidData(dataInput) {
      const datafile = dataInput.map(element => ({
        ...element,
        money: parseInt(element.price, 10).toString() === element.price ? parseInt(element.price, 10) : 0,
        dateTime: Date.parse(element.dateTime) ? `${new Date(element.dateTime).toISOString().slice(0, 10).split('-')
          .reverse()
          .join('/')}` : '',
      }))

      const model = {
        listExcel: datafile,
        isValidate: true,
      }
      await this.getApiExcel(model)
      await this.$router.push({ name: 'import-foster' })
    },
  },
}
</script>

<style lang="scss">
#fosterId {
  .text-name {
    color: #227ff4;
  }

  .light-code {
    background: rgba(34, 127, 244, 0.12);
    border-radius: 4px;
  }

  .border-status {
    border-radius: 4px;
  }
}
</style>
