<template>
  <b-modal
    :id="id"
    size="md"
    :title="title"
    cancel-title="Hủy bỏ"
    cancel-variant="outline-primary"
    ok-title="Lưu"
    centered
    no-close-on-backdrop
    @ok="handleCreateFoster"
    @hidden="resetState"
  >
    <validation-observer ref="rule">
      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Mã bồi dưỡng <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customCode"
        >
          <b-form-input
            v-model="dataInput.code"
            placeholder="Mã bồi dưỡng"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Tên người lao động <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="customWorker"
        >
          <v-select
            v-model="dataInput.workerId"
            :class="errors.length > 0 ? 'is-invalid':null"
            :reduce="Value => Value.id"
            label="name"
            :options="dataAllWorker || []"
            :placeholder="'Người lao động'"
          >
            <template
              slot="option"
              slot-scope="option"
            >
              {{ option.name }}
            </template>
            <template #selected-option-container="{ option }">
              <div class="vs__selected">
                {{ option.name }}
              </div>
            </template>
            <template v-slot:no-options="{ searching }">
              <template v-if="searching">
                Không có kết quả.
              </template>
              <em
                v-else
              >Không có dữ liệu</em>
            </template>
          </v-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Tên hiện vật</label>
        <b-form-input
          v-model="dataInput.content"
          placeholder="Tên hiện vật"
        />
      </b-form-group>

      <b-form-group
        class="mt-1"
        label-for="user"
      >
        <label for="user">Chi phí</label>
        <b-form-input
          v-model="dataInput.price"
          type="number"
          placeholder="Chi phí"
        />
      </b-form-group>

      <b-form-group
        class="mt-1"
      >
        <label for="user">Ngày áp dụng <span class="text-danger">(*)</span></label>
        <validation-provider
          #default="{ errors }"
          rules="required"
          :custom-messages="{required: 'Ngày áp dụng là bắt buộc'}"
        >
          <date-time-picker
            v-model="dataInput.startDate"
            :disabledInput="true"
            :class="errors.length > 0 ? 'is-invalid':null"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
  BFormGroup,
  BModal,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import DateTimePicker from '@/components/datepicker/DatePicker.vue'

export default {
  components: {
    BFormGroup,
    BModal,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    DateTimePicker,
  },
  props: {
    id: {
      type: String,
      default: 'edit-foster',
    },
    type: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    dataDetailFoster: {
      type: Object,
      default: _ => {},
    },
  },
  data() {
    return {
      required,
      dataInput: {
        code: '',
        workerId: '',
        startDate: '',
        price: 0,
        content: '',
      },
      customWorker: {
        required: 'Tên người lao động là bắt buộc',
      },
      customCode: {
        required: 'Mã bồi dưỡng là bắt buộc',
      },
    }
  },
  computed: {
    ...mapGetters('workerPermit', ['dataAllWorker']),
  },
  watch: {
    dataDetailFoster(val) {
      if (val && this.type === 'edit') {
        this.dataInput = {
          code: this.dataDetailFoster.code,
          workerId: this.dataDetailFoster.workerId,
          startDate: this.dataDetailFoster.startDate,
          price: this.dataDetailFoster.price,
          content: this.dataDetailFoster.content,
        }
      }
    },
  },
  mounted() {
    this.fetchDataComboboxAllWorker()
  },
  methods: {
    ...mapActions('workerPermit', ['fetchDataComboboxAllWorker']),

    // Tạo quản lý bôi dưỡng
    handleCreateFoster(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.rule.validate().then(success => {
        if (success) {
          this.$emit('handleCreateFoster', this.dataInput)
        }
      })
    },
    resetState() {
      this.dataInput = {
        code: '',
        workerId: '',
        startDate: '',
        price: 0,
        content: '',
      }
    },
  },
}
</script>
